<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="50rem"
    class="add-new-folder-modal"
  >
    <div class="add-new-folder-modal-container">
      <div class="add-new-folder-modal-container__header">
        <h1>{{ modalTitle }}</h1>
      </div>
      <div class="add-new-folder-modal-container__body">
          <a-upload
            v-if="(modalTitle === 'Add a New Organization' || modalTitle === 'Edit Organization' ) && activeFolderTab === 'organization_folders'" 
            v-model:file-list="folderIcon"
            list-type="picture-card"
            :maxCount="1"
            :beforeUpload="()=>{return false}"
            accept='image/png, image/jpeg, image/webp, image/jpg'
            class="upload-container"
          >
          <div class="upload-btn" v-if="folderIcon.length === 0">
            <div class="upload-btn__icon">
              <img src="@/assets/icons/add_photo.svg" />
            </div>
            <h4 class="upload-btn__title">Add Organization Logo</h4>
          </div>
          <a-spin v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <div>Uploading...</div>
          </a-spin>
        </a-upload>
        <input
          v-model="folderName"
          type="text"
          placeholder="Please enter name"
          class="add-new-folder-modal-container__body--input"
        />
        <p class="add-new-folder-modal-container__body--error-msg" v-if="error">This field can not be empty</p>
        <!-- <input
        v-model="folderTopic"
        type="text"
        placeholder="Enter Interest Topic"
        class="add-new-folder-modal-container__body--input"
      /> -->
        <a-button :loading="loader" @click="createFolder" class="add-new-folder-modal-container__body--btn">
          {{ button }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    visible: Boolean,
    modalTitle: String,
    loader: Boolean,
    action: String,
    folderToBeEdit: Object
  },
  watch: {
    async visible(val) {
      if (val) {
        this.error = false
        if (this.action === 'edit') {
          this.folderName = this.folderToBeEdit.name;
          this.folderIcon = this.folderToBeEdit?.icon ? [{id:1, status:'uploading'}] : []
          // this.button = 'Edit Folder'  
          const imageObject = await this.urlToObject(this.folderToBeEdit?.icon);
          this.folderIcon = this.folderToBeEdit?.icon ? [imageObject] : [];
        } else {
           this.folderName = ""
        }

        if (this.activeFolderTab === 'organization_folders' && (this.modalTitle === 'Add a New Organization' || this.modalTitle === 'Edit Organization')){
          this.action === 'edit' ? this.button = 'Edit Organization' : this.button = 'Add Organization'
        }else{
          this.action === 'edit' ? this.button = 'Edit Folder' : this.button = 'Add Folder'
        }
      }
      else{
        this.folderIcon = []
      }
    }
  },
  data() {
    return {
      folderName: "",
      folderTopic:"",
      error: false,
      button: 'Add Folder',
      folderIcon: '',
      loading: false
    }
  },
  emits: ["close-modal"],
  computed: {
    ...mapGetters({activeFolderTab:'folders/activeFolderTab'})
  },
  methods: {
    closeModal() {
      this.folderName = "";
      this.folderTopic = ""
      this.folderIcon = []
      this.$emit("close-modal");
    },
    createFolder() {
      if (this.folderName === "") {
          this.error = true
          return false;
      }
    const payload = {
      name: this.folderName,
      folder_tab: this.activeFolderTab,
      icon: this.folderIcon
    }
      if (this.action === 'edit') {
        this.$emit("edit-folder", payload);
      } else {
        this.$emit("create-folder", payload);
      }
    },
    async urlToObject(url, title = "publication") {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = blob.type.includes("pdf")
        ? `${title.slice(0,96)}.pdf`
        : `image.${blob.type.split("/")[1]}`;
      const file = new File([blob], fileName, { type: blob.type });
      // const pdfIcon = new File([blob], `image.${blob.type}`, { type: blob.type });
      const data = {
        uid: "vc-upload-" + Date.now(),
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        size: file.size,
        type: file.type,
        originFileObj: file,
        thumbUrl: URL.createObjectURL(blob),
      };
      return data;
    },
  },
};
</script>

<style lang="scss">
.add-new-folder-modal {
  top: 15rem !important;
  .ant-modal-content {
    background-color: #f6f8ff;

    .ant-modal-close {
      margin: 1.9rem 2rem;
    }
    .ant-modal-body {
      padding: 0;
      .add-new-folder-modal-container {
        padding: 2.2rem 2.5rem;
        &__header {
          margin-bottom: 3.5rem;
          h1 {
            font-size: 2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2.3rem;
            color: $color-black;
          }
        }
        &__body {
          display: flex;
          flex-direction: column;
          .upload-container {
            margin-bottom: 1rem;
            margin-top: -1rem;
            .ant-upload {
              width: 100%;
              background-color: $color-white;
              margin: 0;
              height: 8rem;
              border: 1px dashed $color-dark-grey-5;
              .upload-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $color-white;
                padding: 1rem;
                &__icon {
                  background-color: rgb(228, 230, 235);
                  height: 4.5rem;
                  width: 4.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100%;
                  margin-bottom: 1rem;
                  img {
                    width: 2.5rem;
                    opacity: 0.4;
                  }
                }
                &__title {
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  line-height: normal;
                  margin-bottom: 0;
                  color: $color-black;
                  opacity: 0.5;
                }
              }
            }
            .ant-upload-list {
              display: flex;
              align-items: center;
              justify-content: center;
              .ant-upload-list-picture-card-container {
                height: 12rem;
                width: 12rem;
                float: none;
                .ant-upload-list-item {
                  border-color: $color-dark-grey-5 !important;
                  margin: 0;
                  padding: 0.5rem;
                  height: 12rem;
                  width: 12rem;
                  .ant-upload-list-item-actions {
                    a {
                      display: none !important;
                    }
                  }
                  .ant-upload-list-item-info {
                    .ant-upload-list-item-uploading-text {
                      font-size: 1.2rem;
                      font-family: $font-primary;
                      line-height: normal;
                      margin-bottom: 0;
                      color: $color-black;
                      margin-top: 4rem;
                    }
                    .ant-upload-list-item-thumbnail,
                    .ant-upload-list-item-image {
                      position: static;
                      display: block;
                      width: 100%;
                      height: 100%;
                      -o-object-fit: contain;
                      object-fit: contain;
                    }
                  }
                }
              }
            }
          }
          &--input {
            height: 4rem;
            border: 1px solid $color-dark-grey-5;
            padding: 0 1rem;
            color: $color-black;
            font-size: 1.4rem;
            font-family: $font-primary;
            width: 100%;
            outline: none;
            border-radius: 5px !important;
            margin-bottom: 1.5rem;
            &::placeholder {
              color: $color-black;
              opacity: 0.5;
            }
          }
          &--error-msg {
            font-size: 1.4rem;
            font-family: $font-secondary;
            color: red;
            margin-bottom: 0;
            margin-top: -0.5rem;
          }
          &--btn {
            margin-top: 1.9rem;
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 4rem;
            display: flex;
            align-items: center;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
            padding: 0 2rem !important;
            .anticon {
              position: absolute !important;
              margin: 0 !important;
              display: block;
              .anticon-spin {
                font-size: 2rem;
              }
            }
            span {
              display: contents;
              position: relative !important;
              margin-bottom: 1.8rem;
            }
          }
        }
      }
    }
  }
}
</style>
